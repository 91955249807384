import { Col, Container, Row } from "reactstrap"
import NavbarComp from "../../../../Components/Navbar"
import { FaAngleLeft } from "react-icons/fa";
import Masthead from "../../../../Components/Masthead";


const AllBookinngDetails = ({data, step}) => {
    return(
        <>
         <Container>
            <Row>
                <Col md="12">
                    <div className="navbar">
                    <Masthead />
                    </div>
                    
                    <div className="back">
                        <span
                            onClick={() => step(0)}
                            style={{ cursor: "pointer"}}
                        >
                         <FaAngleLeft /> Back
                        </span>
                    </div>
                </Col>
                <h2 className="text-center w-100">Booking Information</h2>
                <Row className="mx-5 mt-5">
                <Col md="3">
                    <label className="dogform-label">Service Type</label>
                    <p className="dogform-text">{data.service_type || "-"}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">No. of dogs</label>
                    <p className="dogform-text">{data?.dogs?.length || "-"}</p>
                </Col>

                <Col md="3">
                    <label className="dogform-label">Dates</label>
                    <p className="dogform-text">{data.dates || "-"}</p>
                </Col>
                </Row>

                <Row className="mx-5 mt-5">
                    <h5 >Booking Location</h5>
                    {data.location_type && data.location_type.includes('sitter_location') && <div xs={12} sx={{mt:2}}>
                        <p><span>Sitter's Home</span></p>
                    </div>}
                    {data.location_type && data.location_type.includes('parent_location') && <div xs={12} sx={{mt:2}}>
                        <p><span>Parent's Home</span></p>
                    </div>}
                            {data.location_type && data.location_type.includes('other_address') &&  <div xs={12} sx={{mt:2}}>
                        <p><span>Other Address: </span>{(data?.manual_address && (data?.manual_address?.street_name + ' ' + data?.manual_address?.city + ' ' + data?.manual_address?.country + ' ' + data?.manual_address?.postal_code))  || '-' || "-"}</p>
                    </div>}
                </Row>
            </Row>
        </Container>
        </>
    )
}

export default AllBookinngDetails