import { Col, Row } from "reactstrap"
import { FaAngleLeft } from "react-icons/fa";
import NavbarComp from "../../../../Components/Navbar";
import './style.css'
import Masthead from "../../../../Components/Masthead";

const DogFormData = ({data, step}) => {
    console.log(data, 'form')
    return(
        <div>
            <Row>
                <Col md="12">
                    <div className="navbar">
                            <Masthead />
                        </div>
                    <div className="back">
                        <span
                            onClick={() => step(0)}
                            style={{ cursor: "pointer"}}
                        >
                        <FaAngleLeft /> Back
                        </span>
                    </div>
                </Col>
            </Row>
            <div className="container">
            <Row className="mx-5 mt-5">
                <Col md="3">
                    <label className="dogform-label">Dog Name</label>
                    <p className="dogform-text">{data.name}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Dog Breed</label>
                    <p className="dogform-text">{data.breed}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Dog Gender</label>
                    <p className="dogform-text">{data.gender}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Neutered</label>
                    <p className="dogform-text">{data.is_neutered}</p>
                </Col>
            </Row>

            <Row className="mx-5 mt-3">
                
                <Col md="3">
                    <label className="dogform-label">What age category of the dog?</label>
                    <p className="dogform-text">{data.age_category}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">What size category of the dog?</label>
                    <p className="dogform-text">{data.size_category}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Is the dog friendly with other dogs?</label>
                    <p className="dogform-text">{data.is_friendly_with_other_dogs}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Details (friendly with other dogs?)</label>
                    <p className="dogform-text">{data.is_friendly_with_other_dogs_text || '-'}</p>
                </Col>
            </Row>

            <Row className="mx-5 mt-3">
                <Col md="3">
                    <label className="dogform-label">Is the dog comfortable around cats</label>
                    <p className="dogform-text">{data.is_comfortable_around_cat}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Details (comfortable around cats)</label>
                    <p className="dogform-text">{data.is_comfortable_around_cat_text || '-'}</p>
                </Col> 
                <Col md="3">
                    <label className="dogform-label">How does the dog react to children?</label>
                    <p className="dogform-text">{data.reaction_toward_children}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">How does the dog react to strangers?</label>
                    <p className="dogform-text">{data.reaction_toward_stranger}</p>
                </Col>
            </Row>

            <Row className="mx-5 mt-3">
                <Col md="3">
                    <label className="dogform-label">Is the dog completely toilet trained?</label>
                    <p className="dogform-text">{data.is_toilet_trained}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Details (toilet trained?)</label>
                    <p className="dogform-text">{data.is_toilet_trained_text || '-'}</p>
                </Col> 
                <Col md="3">
                    <label className="dogform-label">Does the dog chew or damage things?</label>
                    <p className="dogform-text">{data.chew_or_damage_things}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Is the dog aggressive in any situation?</label>
                    <p className="dogform-text">{data.is_aggressive}</p>
                </Col>
            </Row>

            <Row className="mx-5 mt-3">
                <Col md="3">
                    <label className="dogform-label">Is the dog fully vaccinated?</label>
                    <p className="dogform-text">{data.is_vaccinated}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Any details shared:</label>
                    <p className="dogform-text">{data.additional_info || '-'}</p>
                </Col> 
                <Col md="3">
                    <label className="dogform-label">Can the dog be left alone for some time?</label>
                    <p className="dogform-text">{data.can_left_alone}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">For how long?</label>
                    <p className="dogform-text">{data.can_left_how_long}</p>
                </Col>
                
            </Row>

            <Row className="mx-5 mt-3">
               <div>
                <h3>Dog's Images</h3>
                {data?.photos?.map((res) => {
                        return(
                            <a href={res} target="_blank">
                            <img className="mx-3" width={150} height={150} src={res}/>
                            </a>
                        )
                    })}
               </div>
            </Row>
            </div>
        </div>
    )
}

export default DogFormData